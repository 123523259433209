import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./AboutSupport.module.css";
import { Col, Row, Collapse } from "antd";
import { useState } from "react";
import planner from "../../img/planner.png";
import React from "react";

const AboutSupport = () => {
  const { t } = useTranslation();

  const [shown, setShown] = React.useState(false);

  const { Panel } = Collapse;

  const text1 = `
  SMEsGoDigital offers a comprehensive assessment test that empowers you to evaluate your digital maturity and identify areas for improvement. The assessment examines key aspects such as technology infrastructure, data utilization, customer experience, and organizational agility. By taking this test, you will gain valuable insights into your current digital capabilities and receive a customized roadmap to accelerate your digital transformation journey.
`;
  const text2 = `
  Embarking on your digital transformation journey is an exciting endeavor that holds the key to unleashing your organization's true potential. SMEsGoDigital offers a clear roadmap to guide you every step of the way with three distinct levels tailored to meet your unique needs: Basic, Standard, or Advanced level. No matter your roadmap level, you will be guided through the entire process, ensuring a smooth and successful digital transformation.
`;
  const text3 = `
  We understand that support and guidance are crucial for your success. Comprehensive support options tailored to meet your organization's specific needs are always handy. As such you can attend the various online training modules available, and, if not enough, then you can get a freemium interview and even have a follow-up consultancy with our extended services.
`;

  type ExpandIconPosition = "start" | "end";

  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <HelmetComponent
        title={t("SMEs_Go_Digital") || undefined}
        descrition={t("SMEs_Go_Digital") || undefined}
      />

      <div className={classes.container}>
        <span className={classes.header1}>
          {t("Join Us in Empowering SMEs Through Digital Transformation!")}
        </span>
        <div className={classes.text}>
          {t(
            "Are you passionate about helping small and medium-sized enterprises (SMEs) thrive in the digital age? Do you have expertise in digital transformation strategies that can revolutionize their businesses? If so, we invite you to be a part of a meaningful initiative that can make a significant impact on SMEs' growth  and success."
          )}
          {/* <br />
          <br />
          {t(
            "The Erasmus+ project #SMEsGoDigital firmly supports that every business, regardless of its size, should have the opportunity to harness the power of digital technologies to enhance their operations and reach new heights. To achieve this vision, we are launching a special session in our platform, to provide 20-minute free consultation meetings for SMEs looking to embark on their digital transformation journey."
          )} */}
        </div>
        <span className={classes.header2}>
          {t("Why Should You Participate?")}
        </span>
        <div className={classes.text}>
          {t(
            "Make a Difference: By offering your expertise, you can contribute to the growth and prosperity of SMEs, which are the backbone of many economies."
          )}
          <br />
          <br />
          {t(
            "Expand Your Network: Engage with a diverse range of SMEs and build valuable connections within the business community."
          )}
          <br />
          <br />
          {t(
            "Showcase Your Expertise: Demonstrate your digital consulting skills and establish your authority in the field."
          )}
          <br />
          <br />
          {t(
            "Generate Leads: This initiative can be an excellent source of potential clients who may seek your services for their digital transformation projects."
          )}
        </div>
        <span className={classes.header2}>{t("How It Works:")}</span>
        <div className={classes.text}>
          {t(
            "Sign Up: Express your interest by registering as a digital consultant for our program"
          )}
          <br />
          <br />
          {t(
            "Consultation Availability: Set your availability for 20-minute consultation slots"
          )}
          <br />
          <br />
          {t(
            "Consultation Meetings: Conduct 20-minute virtual consultations with SMEs to provide insights, answer questions, and offer initial guidance on their digital transformation."
          )}
          <br />
          <br />
          {t(
            "Feedback and Follow-up: Share feedback on the consultations, and if both parties agree, you can continue discussions beyond the initial meeting. Join Us in Making a Difference!"
          )}
          <br />
          <br />
          {t("To get started, please fill the")}{" "}
          <a
            href="https://docs.google.com/forms/d/1zMx3DcpuS8uuOC-hPDRXlBH6qNHgVp9ldTmoYnQjYRs/viewform?pli=1&pli=1&edit_requested=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("registration form")}
          </a>{" "}
          {t(
            "and sign up as a digital consultant. Your commitment to this initiative will help SMEs navigate the complexities of digital transformation and unlock their full potential."
          )}
          <br />
          <br />
          {t(
            "If you have any questions or need further information, please do not hesitate to reach out to our team at"
          )}{" "}
          <a href="mailto:godigitalsmes@gmail.com">godigitalsmes@gmail.com</a> .
        </div>
      </div>
    </>
  );
};

export default AboutSupport;
