import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import i18n from "./translation";
import { I18nextProvider } from "react-i18next";
// import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./routes/Home/Home";
import FreemiumInterview from "./routes/FreemiumInterview/FreemiumInterview";
import AboutSupport from "./routes/AboutSupport/AboutSupport";
import { Styles } from "./styles/styles";
import Login from "./routes/Login/Login";
import Navigation from "./components/Navigation/Navigation";
import RSSFeedPage from "./routes/RSSFeedPage/RSSFeedPage";
import { NavigationContextProvider } from "./context/NavigationContext";
import PrivateRoutes from "./utils/PrivateRoutes/PrivateRoutes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const storedLanguage = localStorage.getItem("selectedLanguage");
if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <NavigationContextProvider>
          <Styles />
          <Navigation />
          {/* <br />
        <Header /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-support" element={<AboutSupport />} />
            <Route path="/login" element={<Login />} />
            <Route path="/rss-feeds" element={<RSSFeedPage />} />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/freemium-interview"
                element={<FreemiumInterview />}
              />
            </Route>
          </Routes>
          <Footer />
        </NavigationContextProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
