// NavigationContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

interface NavigationContextProps {
  children: ReactNode;
}

interface NavigationContextState {
  showSelfAssessment: boolean;
  setShowSelfAssessment: (value: boolean) => void;
  showRoadmaps: boolean;
  setShowRoadmaps: (value: boolean) => void;
  showTraining: boolean;
  setShowTraining: (value: boolean) => void;
  showMentoring: boolean;
  setShowMentoring: (value: boolean) => void;
  showSupport: boolean;
  setShowSupport: (value: boolean) => void;
}

const NavigationContext = createContext<NavigationContextState | undefined>(
  undefined
);

export const useNavigationContext = (): NavigationContextState => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      "useNavigationContext must be used within a NavigationContextProvider"
    );
  }
  return context;
};

export const NavigationContextProvider = ({
  children,
}: NavigationContextProps) => {
  const [showSelfAssessment, setShowSelfAssessment] = useState<boolean>(false);
  const [showRoadmaps, setShowRoadmaps] = useState<boolean>(false);
  const [showTraining, setShowTraining] = useState<boolean>(false);
  const [showMentoring, setShowMentoring] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);

  const value: NavigationContextState = {
    showSelfAssessment,
    setShowSelfAssessment,
    showRoadmaps,
    setShowRoadmaps,
    showTraining,
    setShowTraining,
    showMentoring,
    setShowMentoring,
    showSupport,
    setShowSupport,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
