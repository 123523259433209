import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "antd";
import classes from "./LoginModal.module.css";
import { useTranslation } from "react-i18next";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface LoginModalProps {
  visible: boolean;
  closeModal: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ visible, closeModal }) => {
  const { t } = useTranslation();
  const [size, setSize] = useState<SizeType>("large");

  return (
    <Modal
      title={t("To access this feature, please log in to your account.")}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      className={classes.modal}
    >
      <Link to="/login">
        <Link to="/login">
          <br />
          <Button className={classes.signin} type="primary" size={size}>
            {t("SIGN IN/REGISTER")}
          </Button>
          <br /><br />
        </Link>
      </Link>
    </Modal>
  );
};

export default LoginModal;
