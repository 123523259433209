import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Collapse } from "antd";
import HelmetComponent from "../../components/Helmet/Helmet";
import { useNavigationContext } from "../../context/NavigationContext"; // Import the useNavigationContext hook

import classes from "./Home.module.css";
import planner from "../../img/planner.png";
import registerImg from "../../img/smes-register-now.png";
import LoginModal from "../../components/LoginModal/LoginModal";

const Home = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const {
    setShowSelfAssessment,
    setShowRoadmaps,
    setShowTraining,
    setShowMentoring,
  } = useNavigationContext(); // Access the setShowSelfAssessment function from the NavigationContext

  const handleSelfAssessmentClick = () => {
    if (window.localStorage.getItem("access_token")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowSelfAssessment(true);
      setShowRoadmaps(false);
      setShowTraining(false);
      setShowMentoring(false);
    } else setIsModalVisible(!isModalVisible);
  };
  const handleRoadmapsClick = () => {
    if (window.localStorage.getItem("access_token")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowRoadmaps(true);
      setShowSelfAssessment(false);
      setShowTraining(false);
      setShowMentoring(false);
    } else setIsModalVisible(!isModalVisible);
  };
  const handleTrainingClick = () => {
    if (window.localStorage.getItem("access_token")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowTraining(true);
      setShowRoadmaps(false);
      setShowSelfAssessment(false);
      setShowMentoring(false);
    } else setIsModalVisible(!isModalVisible);
  };
  const handleMentoringClick = () => {
    if (window.localStorage.getItem("access_token")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowMentoring(true);
      setShowTraining(false);
      setShowRoadmaps(false);
      setShowSelfAssessment(false);
    } else setIsModalVisible(!isModalVisible);
  };

  const [shown, setShown] = React.useState(false);

  const { Panel } = Collapse;

  const text1 = (
    <div>
      {t("SMEsGoDigital offers a comprehensive")} {/* <Link to={}> */}
      <strong>
        <u onClick={() => handleSelfAssessmentClick()}>{t("assessment")}</u>
      </strong>{" "}
      {t(
        "test that empowers you to evaluate your digital maturity and identify areas for improvement. The assessment examines key aspects such as technology infrastructure, data utilization, customer experience, and organizational agility. By taking this test, you will gain valuable insights into your current digital capabilities and receive a customized roadmap to accelerate your digital transformation journey."
      )}{" "}
    </div>
  );
  const text2 = (
    <div>
      {t(
        "Embarking on your digital transformation journey is an exciting endeavor that holds the key to unleashing your organization's true potential. SMEsGoDigital offers a clear"
      )}{" "}
      <strong>
        <u onClick={() => handleRoadmapsClick()}>{t("roadmap")}</u>
      </strong>{" "}
      {t(
        "to guide you every step of the way with three distinct levels tailored to meet your unique needs: Basic, Standard, or Advanced level. No matter your roadmap level, you will be guided through the entire process, ensuring a smooth and successful digital transformation."
      )}
    </div>
  );
  const text3 = (
    <div>
      {t(
        "We understand that support and guidance are crucial for your success. Comprehensive support options tailored to meet your organization's specific needs are always handy. As such you can attend the various"
      )}{" "}
      <strong>
        <u onClick={() => handleTrainingClick()}>
          {t("online training modules")}
        </u>
      </strong>{" "}
      {t("available.")}
    </div>
  );
  const text4 = (
    <div>
      {t(
        "We understand that support and guidance are crucial for your success. Comprehensive support options tailored to meet your organization's specific needs are always handy. As such you can attend the various online training modules available, and, if not enough, then you can get a"
      )}{" "}
      <strong>
        <u onClick={() => handleMentoringClick()}>{t("freemium interview")}</u>
      </strong>{" "}
      {t("and even have a follow-up consultancy with our extended services.")}
    </div>
  );

  type ExpandIconPosition = "start" | "end";

  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <HelmetComponent
        title={t("SMEs_Go_Digital") || undefined}
        descrition={t("SMEs_Go_Digital") || undefined}
      />

      <div className={classes.container}>
        <LoginModal visible={isModalVisible} closeModal={closeModal} />

        {/* <div className={classes.banner}> */}
        {/* <img src={registerImg} alt="Register now" /> */}
        {/* <Link to="/about-support"> Check it out : <br />Calling all digital consultants!</Link> */}
        {/* </div> */}
        <br />
        <br />
        <Row style={{ justifyContent: "center" }}>
          <Col xl={11} style={{ maxWidth: 441 }}>
            <span className={classes.click}>{t("Read here")}</span>
            <div>
            <h1>
              {t(
                "Register for free access to Assessment, Roadmaps, Training and Mentoring"
              )}
            </h1>
            <p>
              {t(
                "Are you ready to embrace the future of business? Register and sign-in to gain access to all our digital transformation tools. It is"
              )}{" "}
              <strong>{t("totally free of charge")}</strong>{" "}
              {t(
                "and will help you stay ahead in today’s rapidly evolving digital landscape."
              )}
            </p>
            </div>
          </Col>
          <Col xl={13}>
            <img src={planner} alt="planner" />
          </Col>
        </Row>

        <div className={classes.faqContainer}>
          <Collapse
            className={classes.faq}
            defaultActiveKey={["1"]}
            onChange={onChange}
            expandIconPosition={expandIconPosition}
          >
            <Panel
              className={classes.panelContainer}
              header={
                <div className={classes.panel}>
                  <div className={classes.step}>{t("Step 1")}</div>
                  {t("Check your Digital Readiness")}
                </div>
              }
              key="1"
            >
              <div>{text1}</div>
            </Panel>
            <Panel
              className={classes.panelContainer}
              header={
                <div className={classes.panel}>
                  <div className={classes.step}>{t("Step 2")}</div>
                  {t("Get your Roadmap")}
                </div>
              }
              key="2"
            >
              <div>{text2}</div>
            </Panel>
            <Panel
              className={classes.panelContainer}
              header={
                <div className={classes.panel}>
                  <div className={classes.step}>{t("Step 3")}</div>
                  {t("Get Training")}
                </div>
              }
              key="3"
            >
              <div>{text3}</div>
            </Panel>
            <Panel
              className={classes.panelContainer}
              header={
                <div className={classes.panel}>
                  <div className={classes.step}>{t("Step 4")}</div>
                  {t("Mentoring")}
                </div>
              }
              key="4"
            >
              <div>{text4}</div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default Home;
