import axios from "axios";

const url = new URL(window.location.href);

// Define an interface to match the structure of the token payload
interface TokenPayload {
  sub: string;
  jti: string;
  iat: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
  exp: number;
  iss: string;
  aud: string;
}

export interface Action {
  title: string;
}

let accessToken = null;
let refreshToken: any = null;

class AuthService {
  getParameters() {
    let paramString = window.location.href.split("?")[2];
    let params_arr = paramString.split("&");
    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split("=");
      return pair[1];
    }
  }

  async login(url1: any) {
    const keycloakConfig = {
      realm: "SMEs",
      clientId: "WebPortal",
      wellKnownEndpoint:
        "https://auth.dotsoft.gr/auth/realms/SMEs/.well-known/openid-configuration",
    };

    let hint;

    if (url.searchParams.has("code")) {
      const searchParams = new URLSearchParams(url.search);
      const code = searchParams.get("code");

      const response = await axios.get(keycloakConfig.wellKnownEndpoint);
      const tokenEndpoint = response.data.token_endpoint;

      const formData = new URLSearchParams();
      formData.append("grant_type", "authorization_code");
      formData.append("client_id", keycloakConfig.clientId);
      formData.append("code", code!);
      formData.append(
        "redirect_uri",
        `${process.env.REACT_APP_DOMAIN_NAME}/login`
      );
      formData.append("client_secret", "ty1uR0HmjaCgB5kSQNxEJttL8t4GRCoX"); // Add client_secret

      try {
        const tokenResponse = await axios.post(tokenEndpoint, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const currentTime = Date.now();
        window.localStorage.setItem(
          "access_token",
          tokenResponse.data.access_token
        );
        window.localStorage.setItem(
          "refresh_token",
          tokenResponse.data.refresh_token
        );
        window.localStorage.setItem(
          "id_token_hint",
          tokenResponse.data.id_token
        );
        window.localStorage.setItem("token_timestamp", currentTime.toString());
        if (window.localStorage.getItem("access_token")) {
          (window as Window).location = `${process.env.REACT_APP_DOMAIN_NAME}`;
        }
        // Additional logic or function calls can go here

        const storedAccessToken = window.localStorage.getItem("access_token");
        const storedRefreshToken = window.localStorage.getItem("refresh_token");

        // Handle user information or other actions as needed

        // You can call this function if necessary
        // refreshAccessToken(storedRefreshToken);
      } catch (error) {
        // Handle token exchange failure
        console.error("Error exchanging code for tokens:", error);
      }
    } else {
      //step 1: call authorize service to get code
      (
        window as Window
      ).location = `https://auth.dotsoft.gr/auth/realms/SMEs/protocol/openid-connect/auth?client_id=WebPortal&client_secret=ty1uR0HmjaCgB5kSQNxEJttL8t4GRCoX&response_type=code&scope=openid&redirect_uri=${process.env.REACT_APP_DOMAIN_NAME}/login`;
    }
  }
  // fetch(`${process.env.REACT_APP_SERVER}/user/logout`, {
  //     method: 'GET',
  //     headers: {
  //         "API-KEY": `${process.env.REACT_APP_API_KEY}`,
  //         // "Content-Type": "application/json"
  //     }
  // })
  //     .then(response => {
  //         return response.text()
  //     })
  //     .then(data => {
  //         console.log(data);
  //         // this.getCurrentUser()

  //         window.localStorage.clear();
  //     })
  //     .then(data => {

  //         (window as Window).location = `${process.env.REACT_APP_URL}/`
  //     })
  //     .then(() => {
  //         window.localStorage.clear();
  //     })
  logout = async () => {
    // const id = window.localStorage.getItem("id");
    const id_token_hint = await window.localStorage.getItem("id_token_hint");

    const id = await window.localStorage.getItem("id");
    const access_token = window.localStorage.getItem("access_token");
    await window.localStorage.clear();

    await axios
      .post(
        `https://auth.dotsoft.gr/auth/admin/realms/SMEs/users/${id}/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error("Error on logout:", error);
      });

    // (window as Window).location = `https://test.gsis.gr/oauth2server/logout/TPQIDO19419?url=https://eata-front.dotsoft.gr/loginuser`;

    // (window as Window).location = `https://auth.dotsoft.gr/auth/realms/EATA/protocol/openid-connect/logout?id_token_hint=${id_token_hint}&post_logout_redirect_uri=https://eata-front.dotsoft.gr/loginuser&kc_idp_hint=gsis-taxis-test`;
  };

  async getCurrentUser() {
    const userStr = await window.localStorage.getItem("user");

    // if (userStr) return JSON.parse(userStr);
    if (userStr) return userStr;
    else {
      // <Navigate to={"/login"} />
      return null;
    }
  }

  getToken() {
    return window.localStorage.getItem("a");
  }

  refreshAccessToken = async () => {
    const refreshToken = window.localStorage.getItem("refresh_token");
    const tokenTimestamp = parseInt(
      window.localStorage.getItem("token_timestamp")!,
      10
    );
    const currentTime = Date.now(); // Current time in milliseconds

    // Calculate the time elapsed in milliseconds
    const timeElapsed = currentTime - tokenTimestamp;
    const oneMinuteInMillis = 60 * 1000; // 1 minute in milliseconds

    if (timeElapsed >= 4 * 60 * 60 * 1000 + 58 * 60 * 1000) {
      const keycloakConfig = {
        realm: "EATA",
        clientId: "WebPortal",
        wellKnownEndpoint:
          "https://auth.dotsoft.gr/auth/realms/EATA/.well-known/openid-configuration",
      };

      const response = await axios.get(keycloakConfig.wellKnownEndpoint);
      const tokenEndpoint = response.data.token_endpoint;

      const formData = new URLSearchParams();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", keycloakConfig.clientId);
      formData.append("refresh_token", refreshToken!);
      formData.append("client_secret", "DYZ7LWonGXBfFjtPqnX1iN5M5kfJoTGg");

      try {
        const tokenResponse = await axios.post(tokenEndpoint, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const newAccessToken = tokenResponse.data.access_token;
        const expiresIn = tokenResponse.data.expires_in;

        window.localStorage.setItem("access_token", newAccessToken);
        window.localStorage.setItem("token_timestamp", currentTime.toString());

        // Schedule the next token refresh
        setTimeout(this.refreshAccessToken, oneMinuteInMillis);
      } catch (error) {
        // Handle token refresh failure, e.g., redirect to login or perform other actions
        console.error("Error refreshing access token:", error);
      }
    } else {
      setTimeout(this.refreshAccessToken, oneMinuteInMillis - timeElapsed);
    }
  };
}

export default new AuthService();
