import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, InputNumber } from "antd";
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  HeartOutlined,
  ShakeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import chat from "../../img/chat.png";
import erasmusImg from "../../img/Erasmus_eu_flag.png";
import ikyLogo from "../../img/iky-logo.jpg";

const Footer = () => {
  const { t } = useTranslation();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "Email field is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <>
      <div className={classes.footerContainer}>
        <Row justify="center">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 15 }}
            lg={{ span: 15 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <img src={ikyLogo} alt="IKY logo" />
              <img className={classes.erasmus} src={erasmusImg} alt="erasmus" />
            </div>
          </Col>
        </Row>
      </div>
      <div className={classes.footerContainer2}>
        <p>
          The European Commission's support for the production of this
          publication does not constitute an endorsement of the contents, which
          reflect the views only of the authors, and the Commission cannot be
          held responsible for any use which may be made of the information
          contained therein.
        </p>
      </div>
      <div className={classes.dotsoft}>
        <span>
          Developed by{" "}
          <a href="https://dotsoft.gr/" target="_blank">
            DOTSOFT
          </a>
        </span>
      </div>
    </>
  );
};

export default Footer;
