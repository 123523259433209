import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translationEn from "./locales/en/translation.json"
import translationEl from "./locales/el/translation.json"
import translationIt from "./locales/it/translation.json"
import translationEs from "./locales/es/translation.json"
import translationCa from "./locales/ca/translation.json"

i18n
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    resources: {
      en: {
        translations: translationEn,
      },
      el: {
        translations: translationEl,
      },
      it: {
        translations: translationIt,
      },
      es: {
        translations: translationEs,
      },
      ca: {
        translations: translationCa,
      }
    },
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
      cookieMinutes: 7 * 24 * 60 * 60 * 1000, // 7 days
    },
    react: { useSuspense: false },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n