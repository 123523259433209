import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

import classes from "./Navigation.module.css";
import { Button } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import LoginModal from "../LoginModal/LoginModal";
import { useNavigationContext } from "../../context/NavigationContext";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";

const FrameSelfAssessment = ({
  src,
  onClose,
}: {
  src: string;
  onClose: () => void;
}) => {
  return (
    <div
      style={{
        backgroundColor: "#34595796",
        display: "flex",
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        bottom: 0,
        left: "0",
        right: 0,
        zIndex: 999999,
      }}
    >
      <iframe
        style={{ borderRadius: 12, margin: 20 }}
        title="Content Frame"
        width="1652"
        height="85%"
        src={src}
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
      ></iframe>
      <button
        style={{
          borderRadius: 25,
          border: "2px solid #027654",
          width: 42,
          height: 42,
          backgroundColor: "#002d47",
          fontSize: "1rem",
          color: "white",
          cursor: "pointer",
          top: "10px",
          position: "fixed",
          lineHeight: 1,
        }}
        onClick={onClose}
      >
        X
      </button>
    </div>
  );
};

const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const {
    showSelfAssessment,
    setShowSelfAssessment,
    showTraining,
    setShowTraining,
    showRoadmaps,
    setShowRoadmaps,
    showMentoring,
    setShowMentoring,
    showSupport,
    setShowSupport,
  } = useNavigationContext();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1250);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  // const [showSelfAssessment, setShowSelfAssessment] = useState<boolean>(false);
  // const [showTraining, setShowTraining] = useState<boolean>(false);
  // const [showRoadmaps, setShowRoadmaps] = useState<boolean>(false);
  // const [showSupport, setShowSupport] = useState<boolean>(false);
  // const [showMentoring, setShowMentoring] = useState<boolean>(false);
  const [size] = useState<SizeType>("large");
  const [subItemsPosition, setSubItemsPosition] = useState<{
    left: number;
  }>({ left: 0 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || i18n.language
  );

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1250);
      if (!isMobile) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowSelfAssessment(false);
    setShowTraining(false);
    setShowSupport(false);
    setShowRoadmaps(false);
    setShowMentoring(false);
  };

  const handleRoadmapsClick = () => {
    if (window.localStorage.getItem("access_token")) {
      setShowRoadmaps(!showRoadmaps);
      setShowMentoring(false);
      setShowTraining(false);
      setShowMenu(false);
      setShowSelfAssessment(false);
      setShowSupport(false);
      calculateSubItemsPosition("roadmaps");
    } else setIsModalVisible(!isModalVisible);
  };

  const handleRoadmapsBasicLevelClick = () => {
    window.open(
      `../../assets/${i18n.language.toUpperCase()}_1.ROADMAP_BASIC_LEVEL.pdf`
    );
  };
  const handleRoadmapsStandardLevelClick = () => {
    window.open(
      `../../assets/${i18n.language.toUpperCase()}_2.ROADMAP_STANDARD_LEVEL.pdf`
    );
  };
  const handleRoadmapsAdvancedLevelClick = () => {
    window.open(
      `../../assets/${i18n.language.toUpperCase()}_3.ROADMAP_ADVANCED_LEVEL.pdf`
    );
  };

  const handleSelfAssessmentClick = () => {
    if (window.localStorage.getItem("access_token")) {
      setShowSelfAssessment(true);
      setShowMenu(false);
      setShowTraining(false);
      setShowSupport(false);
      setShowRoadmaps(false);
      setShowMentoring(false);
    } else setIsModalVisible(!isModalVisible);
  };

  const handleTrainingClick = () => {
    if (window.localStorage.getItem("access_token")) {
      setShowTraining(!showTraining);
      setShowMenu(false);
      setShowSelfAssessment(false);
      setShowSupport(false);
      setShowRoadmaps(false);
      setShowMentoring(false);
    } else setIsModalVisible(!isModalVisible);
  };

  const handleMentoringClick = () => {
    if (window.localStorage.getItem("access_token")) {
      setShowMentoring(!showMentoring);
      setShowTraining(false);
      setShowMenu(false);
      setShowSelfAssessment(false);
      setShowSupport(false);
      setShowRoadmaps(false);
      calculateSubItemsPosition("mentoring");
    } else setIsModalVisible(!isModalVisible);
  };

  const handleFreemiumInterviewClick = () => {
    // window.open('https://www.picktime.com/smesgodigital', '_blank');
    window.location.href = "/freemium-interview";
  };

  const handleSupportClick = () => {
    setShowSupport(!showSupport);
    setShowTraining(false);
    setShowMenu(false);
    setShowSelfAssessment(false);
    setShowRoadmaps(false);
    setShowMentoring(false);
    calculateSubItemsPosition("support");
  };

  const calculateSubItemsPosition = (item: string) => {
    const supportItem = document.getElementById("supportItem");
    const roadmapsItem = document.getElementById("roadmapsItem");
    const mentoringItem = document.getElementById("mentoringItem");
    if (supportItem && item === "support") {
      const rect = supportItem.getBoundingClientRect();
      console.log(rect);

      const supportLeft = rect.left + window.pageXOffset;
      setSubItemsPosition({ left: supportLeft });
      console.log(supportLeft);
    }
    if (roadmapsItem && item === "roadmaps") {
      const rect = roadmapsItem.getBoundingClientRect();
      console.log(rect);

      const roadmapsLeft = rect.left + window.pageXOffset;
      setSubItemsPosition({ left: roadmapsLeft });
      console.log(roadmapsLeft);
    }
    if (mentoringItem && item === "mentoring") {
      const rect = mentoringItem.getBoundingClientRect();
      console.log(rect);

      const mentoringLeft = rect.left + window.pageXOffset;
      setSubItemsPosition({ left: mentoringLeft });
      console.log(mentoringLeft);
    }
  };

  const handleOfferSupportClick = () => {
    window.open(
      "https://docs.google.com/forms/d/1zMx3DcpuS8uuOC-hPDRXlBH6qNHgVp9ldTmoYnQjYRs/viewform?pli=1&pli=1&edit_requested=true",
      "_blank"
    );
  };

  const handleAboutSupportClick = () => {
    window.location.href = "/about-support";
  };

  const handleProjectWebsiteClick = () => {
    window.open("https://godigitalsmes.weebly.com/", "_blank");
  };

  const handleFeedClick = () => {
    window.location.href = "/rss-feeds";
  };

  const closeFrames = () => {
    setShowSelfAssessment(false);
    setShowTraining(false);
    setShowSupport(false);
  };

  const navigationItems = [
    { label: t("HOME"), href: "/" },
    {
      label: t("SELF-ASSESSMENT"),
      onClick: handleSelfAssessmentClick,
    },
    {
      label: t("ROADMAPS"),
      onClick: handleRoadmapsClick,
      subItems: [
        {
          label: t("BASIC LEVEL"),
          onClick: handleRoadmapsBasicLevelClick,
        },
        {
          label: t("STANDARD LEVEL"),
          onClick: handleRoadmapsStandardLevelClick,
        },
        {
          label: t("ADVANCED LEVEL"),
          onClick: handleRoadmapsAdvancedLevelClick,
        },
      ],
    },
    {
      label: t("TRAINING"),
      onClick: handleTrainingClick,
    },
    {
      label: t("MENTORING"),
      onClick: handleMentoringClick,
      subItems: [
        {
          label: t("FREEMIUM INTERVIEW"),
          onClick: handleFreemiumInterviewClick,
        },
      ],
    },
    {
      label: t("OFFER SUPPORT"),
      onClick: handleSupportClick,
      subItems: [
        {
          label: t("OFFER SUPPORT"),
          onClick: handleOfferSupportClick,
        },
        {
          label: t("ABOUT OFFERING SUPPORT"),
          onClick: handleAboutSupportClick,
        },
      ],
    },
    {
      label: t("PROJECT WEBSITE"),
      onClick: handleProjectWebsiteClick,
    },
    {
      label: t("RSS FEEDS"),
      onClick: handleFeedClick,
    },
  ];

  const renderSubItems = (id: number) => {
    if (showRoadmaps && navigationItems[id].subItems) {
      return (
        <div
          className={classes.subItems}
          style={{ left: subItemsPosition.left }}
        >
          {console.log(subItemsPosition.left)!}
          <ul>
            {navigationItems[id].subItems!.map((subItem, subIndex) => (
              <li key={subIndex}>
                <span onClick={subItem.onClick} style={{ cursor: "pointer" }}>
                  {subItem.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    if (showMentoring && navigationItems[id].subItems) {
      return (
        <div
          className={classes.subItems}
          style={{ left: subItemsPosition.left }}
        >
          {console.log(subItemsPosition.left)!}
          <ul>
            {navigationItems[id].subItems!.map((subItem, subIndex) => (
              <li key={subIndex}>
                <span onClick={subItem.onClick} style={{ cursor: "pointer" }}>
                  {subItem.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    if (showSupport && navigationItems[id].subItems) {
      return (
        <div
          className={classes.subItems}
          style={{ left: subItemsPosition.left }}
        >
          {console.log(subItemsPosition.left)!}
          <ul>
            {navigationItems[id].subItems!.map((subItem, subIndex) => (
              <li key={subIndex}>
                <span onClick={subItem.onClick} style={{ cursor: "pointer" }}>
                  {subItem.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderNavItems = () => {
    // const hasAccessToken = window.localStorage.getItem("access_token");
    return navigationItems.map((item, index) => {
      console.log(item);

      const isBlinking =
        (showRoadmaps && item.label === "ROADMAPS") ||
        (showMentoring && item.label === "MENTORING");
      if (!item.href || (index !== 1 && index !== 2)) {
        return (
          <li key={index}>
            {item.href ? (
              <>
                <a href={item.href}>{item.label}</a>
              </>
            ) : (
              <>
                <span
                  className={isBlinking ? classes.blinking : ""}
                  id={
                    index === 2
                      ? "roadmapsItem"
                      : index === 5
                      ? "supportItem"
                      : index === 4
                      ? "mentoringItem"
                      : ""
                  }
                  onClick={
                    index === 2
                      ? handleRoadmapsClick
                      : index === 4
                      ? handleMentoringClick
                      : index === 5
                      ? handleSupportClick
                      : item.onClick
                  }
                  style={{ cursor: "pointer" }}
                >
                  {item.label}
                  {isMobile &&
                    window.localStorage.getItem("access_token") &&
                    item.subItems && (
                      <ul className={classes.subItemsMobile}>
                        {item.subItems!.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <span
                              onClick={subItem.onClick}
                              style={{ cursor: "pointer" }}
                            >
                              {subItem.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                </span>
                {index === 3 && isMobile && showRoadmaps && renderSubItems(2)}
                {index === 4 && isMobile && showMentoring && renderSubItems(4)}
                {index === 5 && isMobile && showSupport && renderSubItems(5)}
              </>
            )}
          </li>
        );
      }
      return null;
    });
  };

  const handleLogout = () => {
    AuthService.logout();
    window.location.reload();
  };

  useEffect(() => {
    if (showSupport) {
      calculateSubItemsPosition("support");
    } else if (showRoadmaps) {
      calculateSubItemsPosition("roadmaps");
    } else if (showMentoring) {
      calculateSubItemsPosition("mentoring");
    }
  }, [showSupport, showRoadmaps, showMentoring]);

  return (
    <div className={classes.navigation}>
      <LoginModal visible={isModalVisible} closeModal={closeModal} />
      <img src="logo.png" alt="logo" className={classes.logo} />
      {isMobile ? (
        <div className={showMenu ? classes.mobileMenuOpen : classes.mobileMenu}>
          <div className={classes.menuIcon} onClick={toggleMenu}>
            {!showMenu ? <MenuOutlined /> : <CloseOutlined />}
          </div>
          {showMenu && (
            <>
              <ul>{renderNavItems()}</ul>
              {window.localStorage.getItem("access_token") ? (
                <Link to="/logout">
                  <Button
                    className={classes.loginMobile}
                    type="primary"
                    onClick={handleLogout}
                    size={size}
                  >
                    {t("LOGOUT")}
                  </Button>
                </Link>
              ) : (
                <Link to="/login">
                  <button className={classes.loginMobile}>
                    {t("SIGN IN/REGISTER")}
                  </button>
                </Link>
              )}
            </>
          )}
        </div>
      ) : (
        <nav className={classes.desktopMenu}>
          <ul>{renderNavItems()}</ul>
          {!isMobile && showRoadmaps && renderSubItems(2)}
          {!isMobile && showMentoring && renderSubItems(4)}
          {!isMobile && showSupport && renderSubItems(5)}
        </nav>
      )}

      <LanguageSwitcher />

      {window.localStorage.getItem("access_token") ? (
        <Link to="/logout">
          <Button
            className={classes.register}
            type="primary"
            onClick={handleLogout}
            size={size}
          >
            {t("LOGOUT")}
          </Button>
        </Link>
      ) : (
        <Link to="/login">
          <button className={classes.loginMobile}>
            {t("SIGN IN/REGISTER")}
          </button>
        </Link>
      )}
      {showSelfAssessment && (
        <FrameSelfAssessment
          src="https://limesurvey.smesgodigital.eu/index.php/535126"
          onClose={closeFrames}
        />
      )}
      {showTraining && (
        <FrameSelfAssessment
          src="https://lms.smesgodigital.eu/"
          onClose={closeFrames}
        />
      )}
    </div>
  );
};

export default Navigation;
