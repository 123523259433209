import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./FreemiumInterview.module.css";

const FreemiumInterview = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetComponent
        title={t("SMEs_Go_Digital") || undefined}
        descrition={t("SMEs_Go_Digital") || undefined}
      />

      <div className={classes.container}>
        <span className={classes.header1}>
          {t("Choose mentor and click on the appropriate link to check their availability")}:
        </span>
        <br /><br />
        <a href="https://calendly.com/jordi-cabanes-costa" target="_blank" rel="noopener noreferrer">Jordi Cabanes Costa (ESP-CAT)</a><br />
        <a href="https://calendly.com/alexrubio_cat" target="_blank" rel="noopener noreferrer">Alex Rubio (ESP-CAT)</a><br />
        <a href="https://calendly.com/smesgodigital/20min" target="_blank" rel="noopener noreferrer">Pavlos Papadimitriou (GRE)</a>
      </div>
    </>
  );
};

export default FreemiumInterview;
