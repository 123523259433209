import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

.ant-select-dropdown {
    background: white;
    min-width: 0px !important;
    max-width: 70px !important;
    width: 50px !important;
    border-radius: 8px;
    top: 45px !important;
}

.ant-collapse {
    background-color: transparent;
}

.ant-collapse-header {
    padding: 12px 16px 12px 0 !important;
}

.ant-select-item-option-active {
    background-color: #263489 !important;
    color: white !important;
}

.ant-collapse-item-active>div:nth-child(1)>span {
    color: #7BB6E1 !important;
}

.ant-collapse-item-active>div:nth-child(1)>div:nth-child(1) span{
    transform: rotate(180deg) !important;
    color: white !important;
}


.ant-collapse-item-active>div:nth-child(2)>div>div{
    color: #021A27 !important;
    border-color: #7BB6E1 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 20px 32px;
}

ul .active:nth-child(1) {
    border-bottom: 4px solid #345957;
}
@media only screen and (max-width: 600px) {
    .ant-select-dropdown {
        background: white;
        border-radius: 8px;
        min-width: 0px !important;
        max-width: 70px !important;
        width: 50px !important;
        top: 42% !important;
        left: 51%;
    }
}

`;