import RSSFeed from "../../components/RSSFeed/RSSFeed";
import classes from './RSSFeed.module.css';

const RSSFeedPage = () => {
  return (
    <div className={classes.container}>
      <RSSFeed />
    </div>
  );
};

export default RSSFeedPage;
