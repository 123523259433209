import classes from "./LanguageSwitcher.module.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
const { Option } = Select;

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || i18n.language
  );

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("selectedLanguage", value);
    setSelectedLanguage(value);
  };

  return (
    <div className={classes.languageSelector}>
      <Select
        value={selectedLanguage}
        onChange={handleChangeLanguage}
        className={classes.languageSelector}
      >
        <Option value="en">EN</Option>
        <Option value="el">ΕΛ</Option>
        <Option value="it">IT</Option>
        <Option value="es">ES</Option>
        <Option value="ca">CA</Option>
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
