import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingIndicator from "../UI/LoadingIndicator/LoadingIndicator";
import classes from "./RSSFeed.module.css";

interface Feed {
  id: string;
  name: string;
}

interface FeedItem {
  id: string;
  title: string;
  description: string;
  source: string;
  category : string;
}

const RSSFeed: React.FC = () => {
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeFeed, setActiveFeed] = useState<string>("");

  useEffect(() => {
    const fetchRSSFeeds = async () => {
      try {
        const response = await axios.get(
          `https://cms.smesgodigital.eu/api/rss-entries/categories`
        );
        console.log(response.data);
        
        setFeeds(response.data);
      } catch (error) {
        console.error("Error fetching RSS feeds:", error);
      }
    };

    fetchRSSFeeds();
  }, []);

  const fetchRSSItems = async (id: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://cms.smesgodigital.eu/api/rss-entries?category_id=${id}`
      );
      console.log(response.data);
      
      setFeedItems(response.data);
    } catch (error) {
      console.error("Error fetching RSS feed items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedClick = async (id: string) => {
    setActiveFeed(id);
    await fetchRSSItems(id);
  };

  return (
    <div>
      <div className={classes.rssFeedContainer}>
        <h2>RSS Feeds</h2>
        {loading && <LoadingIndicator />}
      </div>

      <ul>
        {feeds.map((feed, index) => (
          <li key={index}>
            <button
              className={`${classes.titleBtn} ${
                activeFeed === feed.id ? classes.activeBtn : ""
              }`}
              onClick={() => handleFeedClick(feed.id)}
            >
              {feed.name}
            </button>
          </li>
        ))}
      </ul>

      <br />
      <ul className={classes.items}>
        {feedItems.map((item, index) => (
          <li key={index}>
            <a
              className={classes.linkItemTitle}
              href={item.source}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RSSFeed;
